import { Component, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

declare var $: any;

@Component({
    selector: 'modal-success',
    templateUrl: 'success.component.html',
    styleUrls: ['success.component.scss'],
})
export class ModalSuccessComponent implements OnDestroy {

    message: string;

    emitCloseModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get closeModal() {
        return this.emitCloseModal.asObservable();
    }

    set closeModalData(close: boolean) {
        this.emitCloseModal.next(close);
    }

    constructor(private modalCtrl: ModalController) {}

    ngAfterViewInit(): void {
        let closeAuto = setTimeout(() => {
            this.dismiss();
        }, 3000);
        this.closeModal.subscribe(data => {
            // console.log(data);
            if(data) {
                clearTimeout(closeAuto);
            }
        })
    }
    
    dismiss() {
        return this.modalCtrl.dismiss(null, 'close');
    }

    ngOnDestroy(): void {
        this.closeModalData = true;
    }
}