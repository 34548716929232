import { Component, OnDestroy } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingClientProvider } from 'src/app/services/loading-client';
import { SettingsProvider } from 'src/app/services/settings';
import { XtrasService } from 'src/app/services/xtras.service';

declare var $: any;

@Component({
    selector: 'modal-daily-report',
    templateUrl: 'daily-report.component.html',
    styleUrls: ['daily-report.component.scss'],
})
export class ModalDailyReportComponent implements OnDestroy {
    
    download_excel = null;
    download_pdf = null;
    
    private unsubscribe: Subscription[] = [];
    isLoading: boolean = false;
    
    constructor(
        private modalCtrl: ModalController,
        public xServices: XtrasService,
        private http: HttpClientProvider,
        private load: LoadingClientProvider,
        private iab: InAppBrowser,
        private platform: Platform
    ) {
        this.loadData();
    }
    
    ngAfterViewInit(): void {
        // let closeAuto = setTimeout(() => {
        //     this.dismiss();
        // }, 3000);
        // this.closeModal.subscribe(data => {
        //     // console.log(data);
        //     if(data) {
        //         clearTimeout(closeAuto);
        //     }
        // })
    }
    
    loadData() {
        this.isLoading = true;
        const formSubscr = this.http.postRequest(SettingsProvider.getUrl('movements'), {}).subscribe({
            next:(result:any) => {
                console.log(result);
                if(result['status']) {
                    this.download_excel = result['download_excel'] || null;
                    this.download_pdf = result['download_pdf'] || null;
                    // if(result['message']){
                    //   this.load.presentToast(result['message']);
                    // } else {
                    //   this.load.presentToast('Cobros pendientes obtenidos exitosamente.');
                    // }
                } else {
                    if(result['message']){
                        this.load.presentToast(result['message']);
                    } else {
                        this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
                    }
                }
            },
            complete:() => {
                this.isLoading = false;
            },
            error:(error) => {
                this.load.presentToast('Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
                console.log(error);
            }
        });
        
        this.unsubscribe.push(formSubscr);
    }

    downloadFile(name, file, type) {
        // console.log(file);
        if(file) {
            if(this.platform.is('capacitor')) {
                if(type == 'excel') {
                    this.iab.create(file, '_system');
                } else {
                    this.xServices.saveAsFileBase64(name, file);
                }
            } else {
                const a = document.createElement('a');
                a.href = file;
                a.download = name;
                a.setAttribute('target', '_blank');
                a.click();
            }
        } else {
            this.load.presentToast('No se pudo descargar el archivo.');
        }
    }
    
    dismiss() {
        return this.modalCtrl.dismiss(null, 'close');
    }
    
    ngOnDestroy(): void {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}