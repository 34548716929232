import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { ModalDailyReportComponent } from 'src/app/modals/daily-report/daily-report.component'
import { ModalCloseShiftComponent } from 'src/app/modals/close-shift/close-shift.component';
import { ModalLogoutComponent } from 'src/app/modals/logout/logout.component';
import { AuthUserModel } from '../../interfaces/interfaces';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  user$: Observable<AuthUserModel>;

  constructor(
    private router: Router,
    private menu: MenuController,
    private modalCtrl: ModalController,
    private userService: UserService,
  ) {
    this.user$ = this.userService.currentUserSubject.asObservable();
  }

  ngOnInit() {}

  irInicio() {
    this.router.navigateByUrl('/');
  }

  openCustom() {
    console.log('click');
    this.menu.toggle();
  }

  async viewDownloadReport() {
    const modal = await this.modalCtrl.create({
      mode: 'md', // ios
      component: ModalDailyReportComponent,
      cssClass: 'middle__modal',
      swipeToClose: false,
      // componentProps: { message: message }
    });
    modal.present();
  }


  async closeShift() {
    const modal = await this.modalCtrl.create({
      mode: 'md', // ios
      component: ModalCloseShiftComponent,
      cssClass: 'middle__modal',
      swipeToClose: false,
      // componentProps: { message: message }
    });
    modal.present();
  }

  async logout() {
    const modal = await this.modalCtrl.create({
      mode: 'md', // ios
      component: ModalLogoutComponent,
      cssClass: 'middle__modal',
      swipeToClose: false,
      // componentProps: { message: message }
    });
    modal.present();
  }


}
