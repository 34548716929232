
 import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoadingClientProvider } from './loading-client';
import { LoadingService } from './loading.service';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
declare var $: any;

export interface Data {
    title: string;
}

@Injectable({
    providedIn: 'root',
})
export class XtrasService {

    private emitChangeSource: BehaviorSubject<Data> = new BehaviorSubject<Data>({title: 'QH Organizaciones'});
    get sharingObservable() {
        return this.emitChangeSource.asObservable();
    }
    set sharingObservableData(data: Data) {
        this.emitChangeSource.next({...data});
    }

    private emitChangeFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    get footerObservable() {
        return this.emitChangeFooter.asObservable();
    }
    set footerObservableData(data: boolean) {
        this.emitChangeFooter.next(data);
    }

	private emitChangeItemsCart: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    get itemsCartObservable() {
        return this.emitChangeItemsCart.asObservable();
    }
    set itemsCartObservableData(data: any) {
        this.emitChangeItemsCart.next(data);
    }

    constructor(
        private load: LoadingClientProvider,
        private loading: LoadingService,
        private toastCtrl: ToastController,
        private platform: Platform,
        private fileOpener: FileOpener
    ) {}

    lengthItems(items: any) {
        if(Array.isArray(items)) {
            if(items.length >= 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    formatDate(date?) {
        date = date ? date : new Date();
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

        const dateFormat = new Date(date);
        return `${monthNames[dateFormat.getMonth()]} ${dateFormat.getDate()}, ${dateFormat.getFullYear()}`;
    }

    formatDateNormal(date){
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    filterDataId(id, data) {
        const result = data.filter(itemInArray => itemInArray.id === id);
        // console.log(result);
        return result[0];
    }

    filterDataIds(id, data) {
        let findedData = data.find(i => i.id === id);
        if (typeof findedData === 'undefined') {
            return null;
        }
        return findedData;
    }

    // * object to queryParams
    serialize = function(obj) {
        var str = [];
        for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        return str.join("&");
    }

    getFormattedTime() {
		var today = new Date();
		var y = today.getFullYear();
		// JavaScript months are 0-based.
		var m = today.getMonth() + 1;
		var d = today.getDate();
		var h = today.getHours();
		var mi = today.getMinutes();
		var s = today.getSeconds();
		return y + '_' + m + '_' + d + '_' + h + '_' + mi + '_' + s;
	}

    saveAsFileBase64(filename, fileBase64): any {
        this.loading.loadingShow();
        Filesystem.writeFile({
            path: filename,
            data: fileBase64,
            directory: Directory.Documents
        }).then((val) => {
            // console.log(val);
            Filesystem.getUri({
                path: filename,
                directory: Directory.Documents
            }).then((uri) => {
                console.log(uri);
                this.loading.dismiss();
                if(this.platform.is('capacitor')) {
                    this.presentToast(('Archivo descargado con éxito en "Documents"!'), uri);
                } else {
                    this.load.presentToast(('Archivo descargado con éxito en "Downloads"!'));
                }
                return uri.uri;
            }).catch(err => {
                console.log(err);
                this.load.presentToast('Hubo un error al descargar el archivo, intente nuevamente más tarde.');
                return null
            });
        }).catch(error => {
            console.log(error);
            this.load.presentToast('Hubo un error al descargar el archivo, intente nuevamente más tarde.');
            return null
        })
    }

    private async presentToast(text, uri) {
        let toast = await this.toastCtrl.create({
            message: text,
            duration: 8000,
            buttons: [
                {
                    text: 'Abrir',
                    role: 'info',
                    handler:() => this.openFile(uri.uri, 'application/pdf')
                }
            ]
        }).then((response) => {
            response.present();
        });
    }

    private openFile(uri, mimetype) {
        this.fileOpener.open(uri, mimetype)
            .then(() => console.log('File is opened'))
            .catch(e => console.log('Error opening file', e));
    }


    // private async openFile(uri) {
    //     const contents = await Filesystem.readFile({
    //         path: uri,
    //         // directory: Directory.Documents,
    //     });

    //     console.log('data', contents);
    // }

    readFilePath = async (uri) => {
        return from(Filesystem.requestPermissions()).pipe(
            switchMap(_ => (
                console.log(_),
                from(Filesystem.readFile({path: uri}))
            )), // My app crashes here !!!
        );

    };

    extractDataTypeFileBase64(base64) {
        if(base64) {
            let data = { type: 'application', ext: 'pdf' };
            let dataBase64 = base64.split(';')[0].split('data:')[1];
            console.log(data);
            let type = dataBase64.split('/')[0];
            if(type) {
                console.log(type);
                data.type = type;
            }
            let ext = dataBase64.split('/')[1];
            if(ext) {
                console.log(ext);
                data.ext = ext;
            }

            console.log(data);

            return data;
        }
        return null;
    }
}
