import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation';

import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { SettingsProvider } from 'src/app/services/settings';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable, Subscription } from 'rxjs';
import { Data, XtrasService } from 'src/app/services/xtras.service';
import { UserService, UserType } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserModel } from 'src/app/services/user-model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  private unsubscribe: Subscription[] = [];

  back_location = null;

  user$: Observable<UserType>;
  data$: Observable<Data>;
  
  // organizations$: Observable<any>;
  services = [];
  notifications = [];

  constructor(
    private router: Router,
    private nav: NavigationService,
    private http: HttpClientProvider,
    private toast: NotificationService,
    private load: LoadingService,
    public xServices: XtrasService,
    private userService: UserService,
    public authService: AuthService,
    public userModel: UserModel,
    // private state: RouterStateSnapshot
  ) {
    this.data$ = this.xServices.sharingObservable;
    this.user$ = this.userService.currentUserSubject.asObservable();

    
    // this.http.getRequest(SettingsProvider.getUrl('get-web-services')).subscribe(result => {
    //   console.log(result);
    //   if(result['status']) {
    //     if(result['items']) {
    //       this.services = result['items'];
    //     } else {
    //       this.services = [];
    //     }
    //   } else {
    //     this.toast.error('Error al obtener los datos', result['message'], 4000);
    //   }
    // }, error => {
    //   console.log(error);
    //   this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    // });

    // this.http.getRequest(SettingsProvider.getUrl('get-notifications')).subscribe(result => {
    //   console.log(result);
    //   if(result['status']) {
    //     if(result['items']) {
    //       this.notifications = result['items'];
    //     } else {
    //       this.notifications = [];
    //     }
    //   } else {
    //     this.toast.error('Error al obtener los datos', result['message'], 4000);
    //   }
    // }, error => {
    //   console.log(error);
    //   this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    // });
  }

  ngOnInit() {}

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

  back() {
    this.nav.back()
  }

  logout(){
    Swal.fire({
      title: 'Cerrar Sesión',
      text: '¿Desea cerrar su Sesión?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Sí',
    }).then((result) => {
      if(result.value) {
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.cerrarSesion();
      } else if(result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  cerrarSesion() {
    this.authService.logout();
    this.toast.success('Sesión cerrada', 'Cerraste tu sesión exitosamente', 4000);
    let state = this.router.routerState.snapshot;
    console.log(state);
    if(state.url) { this.userModel.setRedirectUrl(state.url); }
  }

  changeOrganization(id:any) {
    Swal.fire({
      title: 'Cambio de Organización',
      text: '¿Desea cambiar de organización?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if(result.value) {
        // Swal.fire('Removed!', 'Product removed', 'success');
        this.confirmChangeOrganization(id);
      } else if(result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire('Cancelled', 'Product still in our database.', 'error');
      }
    });
  }

  confirmChangeOrganization(id: any) {
    const subscr = this.http.getRequest(SettingsProvider.getUrl(`change-organization/${id}`)).subscribe(result => {
      console.log(result);
      if(result['status']) {
        this.load.dismiss();

        // call service user
        this.userService.getUserByToken().subscribe();
        window.location.reload();
      } else {
        this.load.dismiss();
        this.toast.error('Error al cambiar de Organización', result['message'], 4000);
      }
    }, error => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
      // this.errorPage();
    });
    this.unsubscribe.push(subscr);
    // this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  slugChange(Text) {
    return Text
      .toLowerCase()
      .replace(/ /g,'-')
      .replace(/[^\w-]+/g,'')
    ;
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
