import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingClientProvider } from 'src/app/services/loading-client';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsProvider } from 'src/app/services/settings';
import { XtrasService } from 'src/app/services/xtras.service';

declare var $: any;

@Component({
    selector: 'modal-close-shift',
    templateUrl: 'close-shift.component.html',
    styleUrls: ['close-shift.component.scss'],
})
export class ModalCloseShiftComponent implements OnDestroy {

    message: string;

    emitCloseModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get closeModal() {
        return this.emitCloseModal.asObservable();
    }

    set closeModalData(close: boolean) {
        this.emitCloseModal.next(close);
    }

    constructor(
        private modalCtrl: ModalController,
        private loading: LoadingService,
        private http: HttpClientProvider,
        private alertCtrl: AlertController,
        private authService: AuthService,
        private iab: InAppBrowser,
        private router: Router,
        private load: LoadingClientProvider,
        private platform: Platform,
        private xServices: XtrasService
    ) {}

    ngAfterViewInit(): void {
        // let closeAuto = setTimeout(() => {
        //     this.dismiss();
        // }, 3000);
        // this.closeModal.subscribe(data => {
        //     // console.log(data);
        //     if(data) {
        //         clearTimeout(closeAuto);
        //     }
        // })
    }
    
    closeShiftFunc() {
        this.loading.loadingShow(null, 'Cerrando turno, por favor espere.');
        this.http.getRequest(SettingsProvider.getUrl('close-shift')).subscribe({
            next:(result) => {
                console.log(result);
                if(result['status']){
                    if(result['link']) {
                        this.downloadFile(('Liquidación_'+ this.xServices.getFormattedTime() +'.pdf'), result['link']);
                        // this.loading.dismiss(); //** la añadimos en la funcion downloadFile
                        this.load.presentToast(result['message']);
                        this.dismiss();
                        
                        // * No cerrar sesión a pedido de Franco
                        // this.authService.logout();
                        // this.router.navigateByUrl('/auth');
                    } else {
                        this.load.presentToast(result['message']);
                        this.dismiss();

                        // * No cerrar sesión a pedido de Franco
                        // this.authService.logout();
                        // this.router.navigateByUrl('/auth');
                    }
                } else {
                    this.dismiss();
                    this.load.presentToast(result['message']);
                }
            },
            complete:() => {
                this.loading.dismiss();
            },
            error:(error) => {
                console.log(error);
                this.loading.dismiss();
                this.dismiss();
                this.load.presentToast('Error al obtener los datos, no se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
            }
        });
    }

    downloadFile(name, file) {
        if(file) {
            if(this.platform.is('capacitor')) {
                this.xServices.saveAsFileBase64(name, file);
            } else {
              const a = document.createElement('a');
                a.href = file;
                a.download = name;
                a.setAttribute('target', '_blank');
                a.click();
            }
        } else {
            this.load.presentToast('No se pudo descargar el archivo.');
        }
    }
    
    dismiss() {
        return this.modalCtrl.dismiss(null, 'close');
    }

    ngOnDestroy(): void {
        this.closeModalData = true;
    }
}