import { Component, OnInit } from '@angular/core';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AlertController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'
import { Observable, Subscriber, Subscription } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { SettingsProvider } from './services/settings';
import { HttpClientProvider } from './services/http-client';
import { AuthService } from './services/auth.service';
import { NetworkService } from './services/network.service';
import { LoadingClientProvider } from './services/loading-client';
import { AuthUserModel } from './interfaces/interfaces';
import { XtrasService } from './services/xtras.service';
import { AnimationOptions } from 'ngx-lottie';
import { UserService } from './services/user.service';
import { ModalSuccessComponent } from './modals/success/success.component';
import { UserModel } from './services/user-model';

import { MyOneSignalService } from './services/onesignal.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private unsubscribe: Subscription[] = [];

  title = 'Samsung Empresa App';
  pages: Array<{title: string, component?: any, icon: string, params?:any, function?: any}>;
  noSessionPages: Array<{title: string, component?: any, icon: string, params?:any, function?: any}>;
  sessionPages: Array<{title: string, component?: any, icon: string, params?:any, function?: any}>;

  isConnected:boolean = false;

  logged:boolean = false;

  user$: Observable<AuthUserModel>;
  footer$: Observable<boolean>;

  options: AnimationOptions = {
    path: 'assets/samsung.json',
    loop: false,
  };

  optionsSplashScreen: AnimationOptions = {
    // path: 'assets/Samsung Tiendas.json',
    path: 'assets/samsung.json',
    loop: false,
  };

  lottieSplashScreen: boolean = true;

  constructor(
    public platform: Platform,
    // public userModel: UserModel,
    private statusBar: StatusBar,
    private alertCtrl: AlertController,
    private toastController: ToastController,
    private router: Router,
    private navCtrl: NavController,
    private load: LoadingClientProvider,
    // private loading: LoadingClientProvider,
    private http: HttpClientProvider,
    private iab: InAppBrowser,
    private authService: AuthService,
    private networkService: NetworkService,
    private loading: LoadingService,
    private xServices: XtrasService,
    private userService: UserService,
    private modalCtrl: ModalController,
    private userModel: UserModel,
    private oneSignalService: MyOneSignalService,
  ) {
    this.initializeApp();

    this.user$ = this.userService.currentUserSubject.asObservable();
    this.footer$ = this.xServices.footerObservable;

    this.sessionPages = [
      { title: 'Inicio',
        component: 'home',
        icon: "icon-inicio"
      },
      { title: 'Inventario',
        component: 'inventario',
        icon: "icon-inventario"
      },
      { title: 'Reportes de venta',
        component: 'sales-report',
        icon: "icon-reportes"
      },
      { title: 'Productos',
        component: 'products',
        icon: "icon-productos"
      }

      //{ title: 'Cobros Pendientes',       component: 'pending-collections',   icon: "licon-home"      },
      //{ title: 'Pagar Servicios',         component: 'home',                  icon: "licon-home"      },
      // { title: 'Mi Empresa',              component: 'my-company',            icon: "licon-home"      },
      // { title: 'Cobro de Servicios',      component: 'collect-services',      icon: "licon-home"      },
      // { title: 'Generar QR Bancario',     component: 'home',                  icon: "licon-home",     params: {qr_bank: true}  },
      // { title: 'Liquidaciones',           component: 'liquidations',          icon: "licon-list"      },
      // { title: 'Cerrar Turno',            function:  () => this.closeshift(), icon: "licon-exit"      },
      // { title: 'Términos y Condiciones',  component: 'terms-conditions',      icon: "licon-document"  },
      // { title: 'Cerrar Cesión',           function:  () => this.logout(),     icon: "licon-exit"      },
    ];

    this.noSessionPages = [
      { title: 'Iniciar Sesión', component: 'auth/login', icon: "licon-enter" },
      // { title: 'Regístrate', component: 'auth/register', icon: "licon-register" },
    ];

    // //* Cuando se tenga menú
    this.authService.loggedIn$.subscribe(data => {
      // console.log(data);
      if (data) {
        this.logged = data;
        this.pages = this.sessionPages;
      } else {
        this.logged = false;
        this.pages = this.noSessionPages;
      }
    });

  }

  completeSplashScreen(event) {
    // console.log(event);
    this.lottieSplashScreen = false;
    const element = document.querySelector("#splasScreenCap");
    element.classList.add("hidden_splashscreen");
    $('.content_splash-screen-cap').hide();
    // console.log(this.lottieSplashScreen);
  }

  ngOnInit(): void {
    let netSubs = this.networkService.getNetworkStatus().subscribe((connected: boolean) => {
      this.isConnected = connected;
      if (!this.isConnected) {
        console.log('Por favor enciende tu conexión a Internet');
        this.loading.loadingShowNetwork();
      } else {
        this.loading.dismissLoadingNetwork();
      }
    });
    this.unsubscribe.push(netSubs);
    this.platform.ready().then(() => {
      this.oneSignalService.init();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  initializeApp() {
    if(this.platform.is('capacitor')){
      this.platform.ready().then(() => {
        this.statusBar.backgroundColorByHexString('#ffffff');
        this.statusBar.styleDefault();
        this.statusBar.isVisible;
        this.statusBar.show();
      })
    // } else {
    }
  }

  closeshift() {
    this.loading.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl('close-shift')).subscribe(result => {
      console.log(result);
      if(result['success']){
        this.authService.logout();
        // this.load.presentToast(result['message']);
        if(result['link']) {
          this.downloadFile(('Liquidaciones_'+ this.xServices.getFormattedTime() +'.pdf'), result['link']);
        }
        this.router.navigateByUrl('/auth');
      } else {
        // this.load.presentToast(result['message']);
      }
      this.loading.dismiss();
    }, error => {
      console.log(error);
      // this.load.presentToast('Error al obtener los datos, no se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.');
    });
  }

  downloadFile(name, file) {
    if(file) {
      if(this.platform.is('capacitor')) {
        this.xServices.saveAsFileBase64(name, file);
      } else {
        const a = document.createElement('a');
        a.href = file;
        a.download = name;
        a.setAttribute('target', '_blank');
        a.click();
      }
    } else {
      this.load.presentToast('No se pudo descargar el archivo.');
    }
  }

  async logout(){
    let alert = await this.alertCtrl.create({
      mode: 'ios',
      header: 'Cerrar Sesión',
      message: '¿Desea cerrar su Sesión?',
      buttons: [
        {
          text: 'Si',
          handler: () => {
            this.cerrarSesion();
          }
        },
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('cancel clicked');
          }
        }
      ]
    });
    await alert.present();
  }

  cerrarSesionGuest() {
    // this.userModel.successfulLogout();

    // this.navController.navigateRoot('auth/login');
  }

  async cerrarSesion() {
    const toast = await this.toastController.create({
      message: 'Se ha cerrado sesión con exito.',
      duration: 2000
    });
    toast.present();
    this.authService.logout();
    // console.log(this.userModel.login);
    this.router.navigateByUrl('/auth');
  }

  complete(event) {
    console.log(event);
  }

  loopComplete(event) {
    console.log(event);
  }

  async successMakePayment(message, urlClose?) {
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: ModalSuccessComponent,
      cssClass: 'big__modal success__modal',
      swipeToClose: true,
      presentingElement: document.querySelector('#main-content'),
      componentProps: { message: message }
    });
    if(urlClose) {
      modal.onWillDismiss().then(data => {
        // console.log(data);
        setTimeout(() => {
          this.navCtrl.navigateRoot(urlClose);
        }, 500);
      });
    }
    modal.present();
  }


}
