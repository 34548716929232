import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingClientProvider } from 'src/app/services/loading-client';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsProvider } from 'src/app/services/settings';

declare var $: any;

@Component({
    selector: 'modal-logout',
    templateUrl: 'logout.component.html',
    styleUrls: ['logout.component.scss'],
})
export class ModalLogoutComponent implements OnDestroy {

    message: string;

    emitCloseModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get closeModal() {
        return this.emitCloseModal.asObservable();
    }

    set closeModalData(close: boolean) {
        this.emitCloseModal.next(close);
    }

    constructor(
        private modalCtrl: ModalController,
        private loading: LoadingService,
        private http: HttpClientProvider,
        private alertCtrl: AlertController,
        private authService: AuthService,
        private iab: InAppBrowser,
        private router: Router,
        private load: LoadingClientProvider,
        private toastCtrl: ToastController
    ) {}

    ngAfterViewInit(): void {
        // let closeAuto = setTimeout(() => {
        //     this.dismiss();
        // }, 3000);
        // this.closeModal.subscribe(data => {
        //     // console.log(data);
        //     if(data) {
        //         clearTimeout(closeAuto);
        //     }
        // })
    }
    
    logout() {
        this.cerrarSesion();
    }

    async cerrarSesion() {
        const toast = await this.toastCtrl.create({
            message: 'Se ha cerrado sesión con exito.',
            duration: 2000
        });
        toast.present();
        this.authService.logout();
        this.dismiss();
        this.router.navigateByUrl('/auth');
    }
    
    dismiss() {
        return this.modalCtrl.dismiss(null, 'close');
    }

    ngOnDestroy(): void {
        this.closeModalData = true;
    }
}