import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';
import { ModalSuccessComponent } from '../modals/success/success.component';
import { ModalCloseShiftComponent } from '../modals/close-shift/close-shift.component';
import { ModalDailyReportComponent } from '../modals/daily-report/daily-report.component';
import { ModalInvoicesComponent } from '../modals/invoices/invoices.component';
import { ModalLogoutComponent } from '../modals/logout/logout.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MaterialModule } from './material.module';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { ModalSearch } from '../modals/modal-search/modal-search.component';
import { ModalDateComponent } from '../modals/modal-date/modal-date.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    // NavbarComponent,
    CustomToastComponent,
    // * Modals
    ModalSuccessComponent,
    ModalCloseShiftComponent,
    ModalDailyReportComponent,
    ModalInvoicesComponent,
    ModalLogoutComponent,
    ModalDateComponent,
    ModalSearch,
  ],
  entryComponents: [CustomToastComponent],
  imports: [
    MaterialModule,
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CustomToastComponent,
  ]
})
export class ComponentsModule { }
