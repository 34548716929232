import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading = false;
  private isLoadingNetwork = false;
  constructor(
  ) { }

  dismiss() {
    if(this.isLoading) {
      $('.loading_wrapper.loading_normal').remove();
      this.isLoading = false;
    }
  }

  dismissLoadingNetwork() {
    if(this.isLoadingNetwork) {
      $('.loading_wrapper.not_available_network').remove();
      this.isLoadingNetwork = false;
    }
  }

  loadingShow(loadingType?, message?) {
    if(!this.isLoading) {
      // console.log('loading');
      let default_message = '<div class="loads-ellipsis"><div></div><div></div><div></div><div></div></div>';
      if(loadingType && loadingType == 'loading_2'){
        loadingType = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
      } else {
        loadingType = loadingType ? loadingType : default_message;
      }
      // $('body') // * generalmente es en body pero en ionic es mejor dentro de ion-app.main-site
      $('ion-app.main-site').append(`<div class="loading_wrapper loading_normal"><div class="backdrop_loading"></div><div class="loading__content">${loadingType}<p>${message || 'Cargando...'}</p></div></div>`);
      this.isLoading = true;
      // setTimeout(() => {
      //   if(this.isLoading) {
      //     $('.loading_wrapper.loading_normal').remove();
      //     this.isLoading = false;
      //   }
      // }, 10000);
    }
  }

  loadingShowNetwork(loadintType?) {
    if(!this.isLoadingNetwork) {
      // console.log('loading');
      let default_message = '<div class="not_available_network_icon"><ion-icon name="wifi-outline"></ion-icon></div>';
      if(loadintType && loadintType == 'loading_2'){
        loadintType = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
      } else {
        loadintType = loadintType ? loadintType : default_message;
      }
      $('body').append(`<div class="loading_wrapper not_available_network"><div class="backdrop_loading"></div><div class="loading__content">${loadintType}<h3>Sin Internet</h3><p>Por favor, compruebe la conexión y vuelva a intentarlo</p></div></div>`);
      this.isLoadingNetwork = true;
    }
  }
}
