import { Injectable } from '@angular/core';

@Injectable()
export class SettingsProvider {

  // static api_url: string = 'http://qpos.test/';
  static api_url: string = 'https://qpos-dev.qhantuy.com/'; //* TEST
  // static api_url: string = 'https://qpos-prod.qhantuy.com/'; //* PROD
  
  private static endpoints = {
    home: 'api/check-location',
    register: 'api-auth/register',
    registerConfirm: 'api/register-confirm/',
    auth: 'api-auth/authenticate',
    logout: 'api/auth-logout',
    verifyCode: 'api/verify-code/',
    findEvent: 'api/event/',
    getEventPictures: 'api/event-pictures/',
    findPicture: 'api/picture/'
  };



  constructor() {}

  static authRegister(): string{
    return this.api_url + this.endpoints.register;
  }

  static authLogin(): string{
    return this.api_url + this.endpoints.auth;
  }

  static authLogout(): string{
    return this.api_url + this.endpoints.logout;
  }

  static getUrl(url:string): string{
    return this.api_url + 'device-api/' + url;
  }

  static getUrlNotApi(url:string): string{
    return this.api_url + url;
  }

  static getCustomUrl(url:string): string{
    return url;
  }

  static getOldUrl(url): string{
    return this.api_url + 'api/' + url
  }

}
