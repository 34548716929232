import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Network } from '@awesome-cordova-plugins/network/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ComponentsModule } from './components/components.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErroInterceptorService, TokenInterceptorService } from './services/interceptor.service';
import { UserService } from './services/user.service';
import { UserModel } from './services/user-model';
import { LoadingClientProvider } from './services/loading-client';
import { HttpClientProvider } from './services/http-client';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MaterialModule } from './components/material.module';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MyOneSignalService } from './services/onesignal.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

function appInitializer(userService: UserService) {
  return () => {
    return new Promise((resolve) => {
      userService.getUserByToken().subscribe().add(resolve);
    });
  };
}

export function playerFactory() {
  return player
}

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    // IonicModule.forRoot(),
    IonicModule.forRoot({rippleEffect: false, mode: 'md'}),
    AppRoutingModule,
    ComponentsModule,
    MaterialModule,
    LottieModule.forRoot({player: playerFactory}),
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      newestOnTop: false,
      preventDuplicates: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  // entryComponents: [CustomToastComponent],
  providers: [
    Network,
    StatusBar,
    UserModel,
    LoadingClientProvider,
    HttpClientProvider,
    InAppBrowser,
    Clipboard,
    FileOpener,
    MyOneSignalService,
    SocialSharing,
    { provide: MAT_DATE_LOCALE, useValue: 'es-GB' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErroInterceptorService, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UserService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
