// Data WebApp Site
export class UserModel {
    
    title:                  string = 'QH Organizaciones';
    redirect_url:           string = null;
    menu:                   Array<any>;
    organizations:          Array<any>;
    current_organization:   any;

    constructor() {
    }

    successfullPushTokenNotification(token_notification) {
		localStorage.setItem('push_token', token_notification);
	}

    getPushTokenNotification(){
        let push_token = localStorage.getItem('push_token');
        return push_token;
    }

    setRedirectUrl(value){
        this.redirect_url = value;
        localStorage.setItem('redirect_url', this.redirect_url);
    }

    getRedirectUrl(){
        this.redirect_url = localStorage.getItem('redirect_url') || null;
        return this.redirect_url;
    }

    setTitle(value){
        this.title = value;
        localStorage.setItem('title', this.title);
    }
    getTitle(){
        this.title = localStorage.getItem('title') || 'QH Organizaciones';
        return this.title;
    }

    setMenu(value){
        this.menu = value;
        localStorage.setItem('menu', JSON.stringify(this.menu));
    }
    getMenu(){
        this.menu = JSON.parse(localStorage.getItem('menu')) || null;
        return this.menu;
    }

    setOrganizations(value){
        this.organizations = value;
        localStorage.setItem('organizations', JSON.stringify(this.organizations));
    }
    getOrganizations(){
        this.organizations = JSON.parse(localStorage.getItem('organizations')) || null;
        return this.organizations;
    }

    setCurrentOrganization(value){
        this.current_organization = value;
        localStorage.setItem('current_organization', JSON.stringify(this.current_organization));
    }
    getCurrentOrganization(){
        this.current_organization = JSON.parse(localStorage.getItem('current_organization')) || null;
        return this.current_organization;
    }

    uploadOrganizationUser(current_organization, organizations){
        this.setOrganizations(organizations);
        this.setCurrentOrganization(current_organization);
    }
}