import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  // url = '/introduction';
  url = '/auth';

  constructor(private authService:AuthService,private router:Router, private platform: Platform){
    if(this.platform.is('capacitor')) {
      // this.url = '/introduction';
      this.url = '/auth';
    } else {
      this.url = '/auth';
    }
  }
  canActivate(){
    return this.isLoggedIn();
  }

  isLoggedIn(): Observable<true | UrlTree>{
		return this.authService.loggedIn$.pipe(
			map((loggedIn) => loggedIn || this.router.parseUrl(this.url))
		);
	}

}
