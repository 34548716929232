import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/rx';

import 'rxjs/add/operator/map';
import { SettingsProvider } from './settings';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';


// ** EL TOKEN SE ENVÍA INICIALMENTE EN EL "SERVICIO TOKEN-INTERCEPTOR.SERVICE.TS" ----- *


@Injectable()
export class HttpClientProvider {

  timeout_value: number = 40000;
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    public http: HttpClient,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    console.log('Hello HttpClientProvider Provider');
  }

  getRequest(endpoint: string, responseTyp?, params?): Observable<any> {
    this.isLoadingSubject.next(true);
    let data:any;
    if(responseTyp) {
      if(params) {
        data = {params: params, responseType: responseTyp}
      } else {
        data = {responseType: responseTyp}
      }
    } else {
      if(params) {
        data = {params: params}
      }
    }
    return this.http.get(endpoint, data/*, {headers: headers}).timeout(this.timeout_value*/).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  postRequest(endpoint: string, body: any, params?) : Observable<any>{
    this.isLoadingSubject.next(true);
    let data:any;
    if(params) {
      data = {params: params}
    }
    return this.http.post(endpoint, body, data).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  customRequest(endpoint: string, method:string, body?:any) : Observable<any>{
    this.isLoadingSubject.next(true);
    if(method == 'POST') {
      if(body) {
        return this.http.post(endpoint, body).pipe(
          finalize(() => this.isLoadingSubject.next(false))
        );
      } else {
        console.error('Necesita pasar el BODY en este método');
      }
    } else if(method == 'GET') {
      return this.http.get(endpoint).pipe(
        finalize(() => this.isLoadingSubject.next(false))
      );
    } else {
      alert('Necesita envíar un método');
    }
  }

  // Example Multi Responses 
  // let requests = ['list-types-assets/all/1', 'list-types-assets/vehicle/2'];
  // this.http.multipleGetRequest(requests).subscribe(results=> {
  //   console.log(results);
  //   console.log(results[0]);
  //   console.log(results[1]);
  // });

  multipleGetRequest(endpoints: any) : Observable<any>{
    this.isLoadingSubject.next(true);
    let responses = [];
    let response1 = this.http.get(SettingsProvider.getUrl(endpoints[0]))
    let response2 = this.http.get(SettingsProvider.getUrl(endpoints[1]))
    let response3 = null;
    if(endpoints.length > 2) {
      response3 = this.http.get(SettingsProvider.getUrl(endpoints[2]))
    }

    if(endpoints.length > 2) {
      responses = [response1, response2, response3];
    } else {
      responses = [response1, response2];
    }
    
    return forkJoin(responses).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

}
