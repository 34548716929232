import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthInterface } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	// Función para ver si está logeado o no desde TS
	private loggedIn = new BehaviorSubject<boolean>(false);
	loggedIn$ = this.loggedIn.asObservable();
	set changeLoggedIn(value: boolean) {
		this.loggedIn.next(value);
	}
		
	headers:any = null;
	
	constructor(
		private httpClient: HttpClient,
		private router: Router,
	) {
	}
	
	login(body:any){
		console.log(body)
		return this.httpClient.post(SettingsProvider.getUrl('solu-login'), body);
	}
	
	getToken(){
		let auth:any = localStorage.getItem('auth') || '';
		return this._getToken(auth) || null;
	}
	
	_getToken(auth:AuthInterface){
		if(auth) {
			let _auth:any = auth;
			if(JSON.parse(_auth)?.login) {
				return JSON.parse(_auth).access_token;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	generateRefreshToken(){
		let data = {
			access_token: this.getToken(),
			refreshtoken: this.generateRefreshToken()
		}
		return this.httpClient.post(SettingsProvider.getUrl('refresh'), data);
	}

	saveTokens(tokenData :any) {
		let expireDate = new Date().getTime() + (1000 * tokenData['expires_in']);
		const auth:AuthInterface = { login: true, access_token: tokenData['access_token'], refresh_token: tokenData['refresh_token'], expires_in: expireDate };
        localStorage.setItem('auth', JSON.stringify(auth));
	}
	
	haveAccess(){
		var loggintoken=localStorage.getItem('access_token')||'';
		var _extractedtoken=loggintoken.split('.')[1];
		var _atobdata=atob(_extractedtoken);
		var _finaldata=JSON.parse(_atobdata);
		if(_finaldata.role=='admin'){
			return true
		} else{
			alert('you not having access');
			return false
		}
	}

	logout(type?) {
		this.loggedIn.next(false);
		localStorage.removeItem('auth');
		localStorage.removeItem('user');
		// localStorage.clear();
		if(type&&type == 'expired') {
			// alert('Tu sesión a expirado.');
		}
		if(!this.getToken()) {
			this.router.navigate(['auth/']);
		}
	}

	successfullOAuthAuthorization(login, token/*, refreshtoken*/, expires_in) {
		let expireDate = new Date().getTime() + (1000 * expires_in);
		const auth:AuthInterface = { login: login, access_token: token, /*refresh_token: refreshtoken,*/ expires_in: expireDate };
		localStorage.setItem('auth', JSON.stringify(auth));
		this.loggedIn.next(true);
	}

	expiredToken(expirationDate: number) {
		// console.log(expirationDate);
		setTimeout(() => {
			this.logout();
		}, expirationDate);
	}

	getAuth(){
		let auth:any = localStorage.getItem('auth') || '';
		return this._getAuth(auth) || null;
	}
	
	_getAuth(auth:AuthInterface){
		if(auth) {
			let _auth:any = auth;
			if(JSON.parse(_auth)?.login) {
				return JSON.parse(_auth);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}
