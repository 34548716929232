import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { XtrasService } from 'src/app/services/xtras.service';
import { LoadingClientProvider } from 'src/app/services/loading-client';
import Swal from 'sweetalert2';
import { SettingsProvider } from 'src/app/services/settings';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingService } from 'src/app/services/loading.service';

declare var $: any;

@Component({
    selector: 'modal-invoices',
    templateUrl: 'invoices.component.html',
    styleUrls: ['invoices.component.scss'],
})
export class ModalInvoicesComponent implements OnDestroy {
    
    items: any;
    code: any;
    payment_provider_id: any;
    form: any;
    
    oneInvoice:boolean = false;
    
    dataForm = this.fb.group({
        invoice_id: new FormArray([],[Validators.minLength(1),Validators.required]),
    });
    
    selection = new SelectionModel<any>(true, []);
    
    constructor(
        private modalCtrl: ModalController,
        private fb: FormBuilder,
        public xServices: XtrasService,
        private alertCtrl: AlertController,
        private load: LoadingClientProvider,
        private http: HttpClientProvider,
        private loading: LoadingService
    ) {}

    reloadData() {
        this.selection.clear();
        this.items = [];
        this.loading.loadingShow();
        this.http.postRequest(SettingsProvider.getOldUrl('provider-pay'), this.form).subscribe({
        next:(result:any) => {
            console.log(result);
            if(result['status']) {
            this.items = result['items'];
            } else {
            if(result['message']) {
                this.load.presentToast(result['message']);
            } else {
                this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
            }
            }
        }, complete:() => {
            this.loading.dismiss();
        }, error:(error) => {
            this.loading.dismiss();
            this.load.presentToast('Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
            console.log(error);
        }
        });
    }
    
    sendForm() {
        console.log(this.selection.selected);
        // let items = [];
        // this.selection.selected.forEach(element => {
        //     items.push({ id: element['id'], amount: element['amount']});
        // });
        // let data = { payment_provider_id: this.payment_provider_id, items };
        let data = { payment_provider_id: this.payment_provider_id, items: this.selection.selected };
        this.loading.loadingShow();
        this.http.postRequest(SettingsProvider.getOldUrl('provider-process-debts'), data).subscribe({
            next:(result:any) => {
                console.log(result);
                if(result['status']) {
                    this.loading.dismiss();
                    this.dismiss(result);
                } else {
                    this.loading.dismiss();
                    if(result['message']) {
                        this.load.presentToast(result['message']);
                    } else {
                        this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
                    }
                }
            }, error:(error) => {
                this.loading.dismiss();
                this.load.presentToast('Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
                console.log(error);
            }
        });
    }
    
    onCheckChange(event) {
        const formArray: FormArray = this.dataForm.get('invoice_id') as FormArray;
        
        if(event.target.checked){
            formArray.push(new FormControl(event.target.value));
        }
        else{
            let i: number = 0;
            
            formArray.controls.forEach((ctrl: FormControl) => {
                if(ctrl.value == event.target.value) {
                    formArray.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }
    
    ngOnInit(): void {
        console.log(this.items);
        if(this.filterData([1]).length == 1) {
            this.oneInvoice = true;
        } else {
            this.oneInvoice = false;
        }
    }
    
    dismiss(data?) {
        return this.modalCtrl.dismiss((data || null), 'close');
    }
    
    ngOnDestroy(): void {
        
    }
    
    filterData(data) {
        const filterData = data;
        const result = this.items.filter(({ can_pay }) => filterData.includes(can_pay));
        console.log(result);
        return result;
    }
    
    changeInput(value, invoice) {
        console.log(value, invoice);
        let data = { id: value, invoice: invoice };
        this.dismiss(data);
    }
    
    async addItem(item) {
        console.log(this.selection.isSelected(item));
        if(this.code == 'tuves') {
            if(!this.selection.isSelected(item)) {
                const alert = await this.alertCtrl.create({
                    header: 'Agregue el monto que desea',
                    message: 'El monto mínimo es de Bs. 105',
                    inputs: [
                        {
                            name: 'amount',
                            type: 'number',
                            placeholder: 'Monto',
                            value: item.amount,
                            min: 105,
                            // max: 1000,
                        },
                    ],
                    buttons: [
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                            handler: () => {
                                console.log('Cancel')
                            }
                        },
                        {
                            text: 'Confirmar',
                            handler: data => {
                                console.log('confirm')
                                if(data) {
                                    if(data.amount) {
                                        if(data.amount >= 105) {
                                            item['amount'] = data.amount;
                                            this.selection.toggle(item);
                                        } else {
                                            this.load.presentToast('El monto mínimo es de Bs. "105"');
                                            return false;
                                        }
                                    } else {
                                        this.load.presentToast('Es necesario que añada un monto para continuar.');
                                        return false;
                                    }
                                } else {
                                    this.load.presentToast('Es necesario que añada un monto para continuar.');
                                    return false;
                                }
                            }
                        }
                    ],
                });
                
                await alert.present();
            } else {
                this.selection.toggle(item);
            }
        } else {
            this.selection.toggle(item);
        }
    }
}