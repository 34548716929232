import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { ComponentsModule } from 'src/app/components/components.module';
import { MaterialModule } from 'src/app/components/material.module';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingClientProvider } from 'src/app/services/loading-client';
import { XtrasService } from 'src/app/services/xtras.service';
import {MatDatepickerModule} from '@angular/material/datepicker'

@Component({
  selector: 'app-modal-date',
  templateUrl: './modal-date.component.html',
  styleUrls: ['./modal-date.component.scss'],

})


export class ModalDateComponent implements OnInit {

  range = new FormGroup({
    date_init: new FormControl<Date | null>(null),
    date_end: new FormControl<Date | null>(null),
  });

  send:boolean = false;
  send1:boolean = false;
  send2:boolean = false;

  constructor(
    private modalCtrl: ModalController,
    public xServices: XtrasService,
    private load: LoadingClientProvider,
  ) {

  }

  ngOnInit() {}

  dismiss(data?) {
    return this.modalCtrl.dismiss(data || null);
  }

  addEvent(event, complete) {
    console.log(event);
    if(complete == 'start'){
      this.send1 = true;
    }
    if(complete == 'end'){
      this.send2 = true;
    }
    if(this.send1&&this.send2) {
      this.send = true;
    } else {
      this.send = false;
    }
  }

  clearFilters() {
    this.range.patchValue({
      date_init: null,
      date_end: null,
    });
    this.send = false;
  }

  submit(send) {
    if(send) {
      let date = { date_init: this.formatDate(this.range.value.date_init), date_end: this.formatDate(this.range.value.date_end) };
      console.log(date);
      this.dismiss({form: date});
    } else {
      this.dismiss({form: {}});
    }
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }
}
