import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { ComponentsModule } from 'src/app/components/components.module';
import { MaterialModule } from 'src/app/components/material.module';
import { HttpClientProvider } from 'src/app/services/http-client';
import { LoadingClientProvider } from 'src/app/services/loading-client';
import { XtrasService } from 'src/app/services/xtras.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { SettingsProvider } from 'src/app/services/settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.scss'],

})
export class ModalSearch implements OnInit {

  panelOpenState = false;

  data = [];
  isLoading:boolean = false;

  imei_serie = null;

  @Input() action: any = 'get';
  @Input() role? = null;

  private unsubscribe: Subscription[] = [];

  constructor(
    private modalCtrl: ModalController,
    public xServices: XtrasService,
    private http: HttpClientProvider,
    private load: LoadingClientProvider,
  ) {

  }

  ngOnInit() {
    console.log(this.action);
    console.log(this.role)
  }

  dismiss(data?) {
    return this.modalCtrl.dismiss(null || data);
  }

  handleChange(event) {
    console.log(event.detail.value);
    this.imei_serie = event.detail.value;
    this.submit('get');
  }

  submit(action) {
    console.log(action);
    if(this.imei_serie) {
      this.isLoading = true;
      if(action == 'get') {
        const formSubscr = this.http.postRequest(SettingsProvider.getCustomUrl(`https://samsungstores.samsung.com.bo/api/check-stock`), { imei_serie: this.imei_serie, action: 'get', price: 0 }).subscribe({
          next:(result:any) => {
            console.log(result);
            if(result['status']) {
              if(result['data']) {
                this.data = result['data'];
                this.isLoading = false;
              }
            } else {
              this.isLoading = false;
              if(result['message']){
                this.load.presentToast(result['message']);
              } else {
                this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
              }
            }
          },
          error:(error) => {
            this.isLoading = false;
            this.load.presentToast('Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
            if(error.error['message']){
              this.load.presentToast(error.error['message']);
            } else {
              this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
            }
            console.log(error);
          }
        });
        this.unsubscribe.push(formSubscr);
      } else {
        if(this.role) {
          const formSubscr = this.http.postRequest(SettingsProvider.getCustomUrl(`https://samsungstores.samsung.com.bo/api/check-stock`), { imei_serie: this.imei_serie, action: this.action, price: 0 }).subscribe({
            next:(result:any) => {
              console.log(result);
              if(result['status']) {
                if(action == 'add' || action == 'delete') {
                  this.modalCtrl.dismiss(({message: result['message']}));
                } else {
                  if(result['data']) {
                    this.data = result['data'];
                    this.isLoading = false;
                  }
                }
              } else {
                this.isLoading = false;
                if(result['message']){
                  this.load.presentToast(result['message']);
                } else {
                  this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
                }
              }
            },
            error:(error) => {
              this.isLoading = false;
              this.load.presentToast('Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
              if(error.error['message']){
                this.load.presentToast(error.error['message']);
              } else {
                this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
              }
              console.log(error);
            }
          });
          this.unsubscribe.push(formSubscr);
        } else {
          const formSubscr = this.http.getRequest(SettingsProvider.getCustomUrl(`https://samsungstores.samsung.com.bo/api/check-item/${this.imei_serie}/${action}`)).subscribe({
            next:(result:any) => {
              console.log(result);
              if(result['status']) {
                if(action == 'add') {
                  this.dismiss('redirect');
                } else {
                  if(result['data']) {
                    this.data = result['data'];
                    this.isLoading = false;
                  }
                }
              } else {
                this.isLoading = false;
                if(result['message']){
                  this.load.presentToast(result['message']);
                } else {
                  this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
                }
              }
            },
            error:(error) => {
              this.isLoading = false;
              this.load.presentToast('Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
              if(error.error['message']){
                this.load.presentToast(error.error['message']);
              } else {
                this.load.presentToast('Hubo un error al obtener los datos, intente nuevamente.');
              }
              console.log(error);
            }
          });
          this.unsubscribe.push(formSubscr);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
