import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
// import { ToastController, LoadingController, Platform, AlertController } from '@ionic/angular';
// import 'rxjs/add/operator/map';

/*
  Generated class for the LoadingClientProvider provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular DI.
*/
@Injectable()
export class LoadingClientProvider {

  loading;
  loading_page: boolean = false
  is_present: boolean = false
  public unregisterBackButtonAction: any;
  redirectHome: any;
  redirectHomeObserver: any;
  popup_up: boolean = false;

  constructor(
    public http: HttpClient, 
    public platform: Platform, 
    public alert: AlertController,
    public loadingCtrl: LoadingController, 
    public alertCtrl: AlertController, 
    public toastCtrl: ToastController
  ) {}

  async showLoading(last_id:any=undefined){
    if (last_id) {
      this.loading_page = true
    } else {
      this.is_present = true
      this.loading = await this.loadingCtrl.create({
        message: 'Por favor espere..'
      }).then((response) => {
        response.present();
      });
    }
  }

  async showLoadingText(text){
    this.loading = await this.loadingCtrl.create({
      message: text
    }).then((response) => {
        response.present();
    });
  }

  async showError(text){
    this.dismiss();
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
    }).then((response) => {
      response.present();
    });
  }

  async presentToast(text, timeout?) {
    this.dismiss();
    let toast = await this.toastCtrl.create({
      message: text,
      duration: timeout || 3000
    }).then((response) => {
      response.present();
    });
  }

  async dismiss(){
    if (this.is_present) {
      await this.loading.dismiss()
      this.is_present = false
    } else {
      this.loading_page = false
    }
  }

  async dialog(title, message){
    let confirmAlert = await this.alert.create({
      header: title,
      message: message,
      buttons: [{
        text: 'Cancelar',
        role: 'cancel',
        handler: () => {
        }
      }, {
        text: 'Continuar',
        handler: () => {
          // TODO: handler
        }
      }]
    }).then((response) => {
        response.present();
    });
  }

  /* Gestor para evitar cerrado de app con back button */

  // Llamar en el ViewEnter del Page
  public avoidCloseViewEnter(redirect = true): void {
    this.redirectHome = false;
    this.redirectHome = Observable.create(observer => {
      this.redirectHomeObserver = observer;
    });
    this.unregisterBackButtonAction = this.platform.backButton.subscribeWithPriority(10, () => {
      if(redirect==true){
        this.customHandleBackButton(false);
      } else {
        this.customHandleBackButton(true);
      }
    });
  }

  // Llamar en el ViewLeave del Page
  public avoidCloseViewLeave(): void {
    this.unregisterBackButtonAction;
  }

  // Llama a confirmar el cierre de la app
  private customHandleBackButton(out): void {
    if(out) {
      console.log('Imposible cerrar, a menos que se confirme');
      let alert = this.alertCtrl.create({
        header: 'Salir de App',
        message: '¿Desea salir de la aplicación?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              this.popup_up = false;
              console.log('Cancelar');
            }
          },
          {
            text: 'Si',
            handler: () => {
              console.log('Salir de todos modos');
              navigator['app'].exitApp();
            }
          }
        ]
      }).then((response) => {
          response.onDidDismiss().then((resp)=> {
            console.log(resp);
            console.log('cerrar');
            this.popup_up = false;
          });
          if(this.popup_up===false){
            this.popup_up = true;
            response.present();
          }
      });
    }
  }

  checkUpdates(): Observable<any> {
    return this.redirectHome;
  }

}
