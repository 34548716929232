import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation';

// import Swal from 'sweetalert2';
import { MenuController } from '@ionic/angular';
import { UserModel } from 'src/app/services/user-model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  back_location = null;

  @Input() url: boolean;
  @Input() logo: boolean;
  @Input() title: any;
  @Input() defaultUrl: any;
  @Input() urlRedirect: any;

  constructor(
    private router: Router,
    public userModel: UserModel,
    private nav: NavigationService,
    // private toast: NgToastService,
    private sideBar: MenuController
  ) { }

  ngOnInit() {}

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

  back() {
    this.nav.back()
  }

  // async logout(){
  //   Swal.fire({
  //     title: 'Cerrar Sesión',
  //     text: '¿Desea cerrar su Sesión?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     cancelButtonText: 'No',
  //     confirmButtonText: 'Sí',
  //   }).then((result) => {
  //     if(result.value) {
  //       // Swal.fire('Removed!', 'Product removed', 'success');
  //       this.toast.success({detail: 'Sesión cerrada', summary: 'Cerraste tu sesión exitosamente', duration: 4000});
  //       this.cerrarSesion();
  //     } else if(result.dismiss === Swal.DismissReason.cancel) {
  //       // Swal.fire('Cancelled', 'Product still in our database.', 'error');
  //     }
  //   });
  // }

  async cerrarSesion() {
    console.log('cerrando sesión')
    this.router.navigateByUrl('/auth');
  }

  openMenu() {
    // this.menu.enable(true, 'primerMenu');
    this.sideBar.open('primerMenu');
  }

}
