import {Injectable} from '@angular/core';
import * as onesignalPhone from 'onesignal-cordova-plugin';
import { ModalController, NavController, Platform } from "@ionic/angular";
import { ModalSuccessComponent } from '../modals/success/success.component';
import { UserModel } from './user-model';

// const ONESIGNAL_APP_ID = '9d54f25d-ce4c-439d-bf7a-ffe10f158a0f'; //* PROD
const ONESIGNAL_APP_ID = '020cd963-71ac-4884-b01a-351bbe78712d'; //* TEST
const GOOGLE_PROJECT_ID = '476927506104';

@Injectable()
export class MyOneSignalService {


    private initialized: boolean = false;

    private WebPushSDK: any = {
        loaded: false,
        src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
    };

    constructor (
        private platform: Platform,
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private userModel: UserModel,
    ) {
    }

    async init() {
        // Es necesario indicar explícitamente (a través de una variable de entorno,
        // por ejemplo) cuando se va a trabajar en Browser.
        // `this.platform.is('cordova')` no es suficiente ya que Browser es una plataforma
        // que corre sobre Cordova. Aún así, `@ionic-native/onesignal` no tiene una
        // implementación para Browser.

        if (!this.platform.is('cordova')) {
            // return this._initBrowser();
            return;
        }

        return this._initCordova();
    }

    private _initBrowser() {
        return new Promise((resolve, reject) => {
            if (this.initialized === true) {
                return resolve(this);
            }

            this.platform.ready().then(() => {
                // El plugin de Cordova se inyecta en `window['OneSignal']`. Lo eliminamos.
                delete window['OneSignal'];

                this.loadWebPushSDK().then(() => {

                    const OneSignal = window['OneSignal'] || [];
                    const initConfig = {
                        appId: ONESIGNAL_APP_ID,
                        notifyButton: {
                            enable: true
                        },
                        autoResubscribe: true,
                        // autoRegister: true,
                        persistNotification: true,
                    };
                    OneSignal.push(function () {
                        OneSignal.init(initConfig);
                        OneSignal.setExternalUserId(GOOGLE_PROJECT_ID);

                        OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
                            if (isEnabled) {
                                console.log("Push notifications are enabled!");
                                console.log(OneSignal.getUserId());
                                OneSignal.getUserId().then((userId) => {
                                    if(userId) {
                                        console.log("User ID is", userId);
                                    } else {
                                        console.log('not user id');
                                    }
                                })
                            } else {
                                console.log("Push notifications are not enabled yet.");
                                // OneSignal.setSubscription(true);
                                // OneSignal.showSlidedownPrompt({ force: true });
                                // OneSignal.showHttpPrompt({ force: true });
                            }
                        });
                        OneSignal.on('subscriptionChange', function (isSubscribed) {
                            console.log("The user's subscription state is now:", isSubscribed);
                            OneSignal.push(function() {
                                OneSignal.getUserId(function(userId) {
                                    console.log("OneSignal User ID:", userId);
                                    localStorage.setItem('key_os', userId);
                                });
                            });
                        });
                        // OneSignal.registerForPushNotifications();
                    });


                    this.initialized = true;

                    return resolve(this);
                }, (errors: any) => {
                    reject(errors);
                });
            }, (errors: any) => {
                reject(errors);
            });
        });
    }

    private _initCordova() {
        return new Promise((resolve, reject) => {
            if (this.initialized === true) {
                return resolve(this);
            }

            this.platform.ready().then(() => {
                onesignalPhone.default.setAppId(ONESIGNAL_APP_ID);
                // onesignalPhone.default.setNotificationOpenedHandler(function(jsonData) {
                //     console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
                // });

                let $this = this;

                // Open Notification
                onesignalPhone.default.setNotificationOpenedHandler(function(data) {
                    console.log('data - 1', data);
                    console.log(data.notification.rawPayload);
                    $this.navCtrl.navigateRoot('/notifications');
                });

                // Push Notification
                onesignalPhone.default.setNotificationWillShowInForegroundHandler(function(data) {
                    console.log('data - 2', data);
                    console.log(data.getNotification().rawPayload);
                    let payload = data.getNotification().rawPayload;
                    if(payload) {
                        if(payload['custom']) {
                            console.log(payload['custom']);
                            let custom_payload = JSON.parse(payload['custom']);
                            console.log(custom_payload);
                            if(custom_payload['a']&&custom_payload['a']['title']&&custom_payload['a']['title'] == 'success-payment') {
                                $this.successMakePayment(payload['alert'], '/notifications');
                            } else {
                                $this.navCtrl.navigateRoot('/notifications');
                            }
                        } else {
                            $this.navCtrl.navigateRoot('/notifications');
                        }
                    }
                });

                // Prompts the user for notification permissions.
                //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
                onesignalPhone.default.promptForPushNotificationsWithUserResponse(function(accepted) {
                    console.log("User accepted notifications: " + accepted);
                });

                let externalUserId = GOOGLE_PROJECT_ID; // You will supply the external user id to the OneSignal SDK
                // onesignalPhone.default.setExternalUserId(externalUserId);

                window['plugins']?.onesignalPhone.default.setExternalUserId(externalUserId, (results) => {
                    // The results will contain push and email success statuses
                    console.log('Results of setting external user id');
                    console.log(results);

                    // Push can be expected in almost every situation with a success status, but
                    // as a pre-caution its good to verify it exists
                    if (results.push && results.push.success) {
                        console.log('Results of setting external user id push status:');
                        console.log(results.push.success);
                    }

                    // Verify the email is set or check that the results have an email success status
                    if (results.email && results.email.success) {
                        console.log('Results of setting external user id email status:');
                        console.log(results.email.success);
                    }

                    // Verify the number is set or check that the results have an sms success status
                    if (results.sms && results.sms.success) {
                        console.log('Results of setting external user id sms status:');
                        console.log(results.sms.success);
                    }
                });


                this.getUserId().then((data) => {
                    console.log('getId ',data);
                    if(data) {
                        this.userModel.successfullPushTokenNotification(data);
                    } else {
                        console.log('entra aquí');
                        setTimeout(() => {
                            this.getUserId().then(data => {
                                console.log('getId call2 ',data);
                                this.userModel.successfullPushTokenNotification(data);
                            });
                        }, 10000);
                    }
                });

                this.initialized = true;

                return resolve(this);
            });
        });
    }

    /**
    * Tomado de `https://stackoverflow.com/a/42766146/2522130`.
    */
    loadWebPushSDK() {
        return new Promise((resolve, reject) => {
            if (this.WebPushSDK.loaded) {
                resolve(true);
            } else {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.WebPushSDK.src;

                script.onload = () => {
                    this.WebPushSDK.loaded = true;

                    resolve(true);
                };

                script.onerror = () => {
                    reject(false);
                };

                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

    getUserId(){
        return new Promise((resolve, reject) => {
            onesignalPhone.default.getDeviceState((response) => {
                resolve(response.userId);
            });
        });
    }

    async successMakePayment(message, urlClose?) {
        const modal = await this.modalCtrl.create({
          mode: 'ios',
          component: ModalSuccessComponent,
          cssClass: 'big__modal success__modal',
          swipeToClose: true,
          presentingElement: document.querySelector('#main-content'),
          componentProps: { message: message }
        });
        if(urlClose) {
          modal.onWillDismiss().then(data => {
            // console.log(data);
            setTimeout(() => {
              this.navCtrl.navigateRoot(urlClose);
            }, 500);
          });
        }
        modal.present();
    }
}
