import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AuthInterface, AuthUserModel, oAuthInterface } from '../interfaces/interfaces';
import { AuthService } from './auth.service';
import { SettingsProvider } from './settings';
import { UserModel } from './user-model';
import { XtrasService } from './xtras.service';

export type UserType = AuthUserModel | undefined;

@Injectable({
	providedIn: 'root'
})
export class UserService implements OnDestroy {

    private unsubscribe: Subscription[] = [];

    currentUser$: Observable<UserType>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserType>;
    isLoadingSubject: BehaviorSubject<boolean>;

    get currentUserValue(): UserType {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserType) {
        this.currentUserSubject.next(user);
    }

	  constructor(
		    private httpClient: HttpClient,
        private authService: AuthService,
        private userModel: UserModel,
        private xServices: XtrasService,
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();

            // * Consultar a los datos del Usuario
        const subscr = this.getUserByToken().subscribe();
        this.unsubscribe.push(subscr);
    }

    oauthAuthorize(body:any): Observable<any>{
        return this.httpClient.post<AuthInterface>(SettingsProvider.getUrl('oauth-authorize'), body);
    }

    getData(auth:AuthInterface) {
        return auth;
    }

    getUserByToken(): Observable<UserType> {
        const auth = this.authService.getAuth();
        // console.log(auth);
        if (!auth || !auth.access_token) {
            this.authService.changeLoggedIn = false;
            return of(undefined);
        }

        this.authService.changeLoggedIn = true;
        this.isLoadingSubject.next(true);
        return this._getUserByToken(auth.access_token).pipe(
            map((data: any) => {
                // console.log(data);

                if(data['status']) {
                    let itemData = { ...data['data'], name: data['data']['user_name'], role: (data['data']['role'] || 'seller') };
                    return this.userData(itemData);
                }
                return this.userData(null);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

	  _getUserByToken(token: string): Observable<AuthUserModel> {
        // console.log(token);
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        // return this.httpClient.get<AuthUserModel>(SettingsProvider.getUrl('my-account'), {
        return this.httpClient.get<AuthUserModel>(SettingsProvider.getCustomUrl('https://samsungstores.samsung.com.bo/api/my-account'), {
            headers: httpHeaders,
        });
	  }

    userData(user: UserType) {
        // console.log(user);
        if (user) {
            this.currentUserSubject.next(user);
        } else {
            this.authService.logout();
        }
        return user;
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
