import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/auth.guard';
import { EstadisticasPPageModule } from './pages/estadisticas copy/estadisticas-p.module';

const routes: Routes = [
  {
    path: 'introduction',
    loadChildren: () => import('./pages/introduction/introduction.module').then( m => m.IntroductionPageModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule),
    // canActivate: [NologinGuard]
  },
  {
    path:'home',
    loadChildren:()=>import('./pages/home/home.module').then(m=>m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'company',
  //   loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'qr-simple',
    loadChildren: () => import('./pages/qr-simple/qr-simple.module').then( m => m.QrSimplePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'services-pay',
    loadChildren: () => import('./pages/services-pay/services-pay.module').then( m => m.ServicesPayPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pending-collections',
    loadChildren: () => import('./pages/pending-collections/pending-collections.module').then( m => m.PendingCollectionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'movements',
    loadChildren: () => import('./pages/movements/movements.module').then( m => m.MovementsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'movement/:id',
    loadChildren: () => import('./pages/movement-detail/movement-detail.module').then( m => m.MovementDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'closures',
    loadChildren: () => import('./pages/closures/closures.module').then( m => m.ClosuresPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'closure/:id',
    loadChildren: () => import('./pages/closure-detail/closure-detail.module').then( m => m.ClosureDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payments-received',
    loadChildren: () => import('./pages/payments-received/payments-received.module').then( m => m.PaymentsReceivedPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'make-payments',
    loadChildren: () => import('./pages/make-payments/make-payments.module').then( m => m.MakePaymentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'make-payments-details',
    loadChildren: () => import('./pages/make-payments-details/make-payments-details.module').then( m => m.MakePaymentsDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-methods',
    loadChildren: () => import('./pages/payment-method/payment-method.module').then( m => m.PaymentMethodPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./pages/payment-method-type/payment-method-type.module').then( m => m.PaymentMethodTypePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventario',
    loadChildren: () => import('./pages/inventory/inventory.module').then(m => m.InventoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path:'camara/:id',
    loadChildren: () => import('./pages/cam/cam.module').then(m=> m.CamPageModule),
    canActivate:[AuthGuard]
  },
  {
    path:'camara/:id/:action',
    loadChildren: () => import('./pages/cam/cam.module').then(m=> m.CamPageModule),
    canActivate:[AuthGuard]
  },
  {
    path:'create-sale/:id',
    loadChildren: ()=> import('./pages/create-sale/create-sale.module').then(m=> m.CamPageModule)
  },

  {
    path:'ventas',
    loadChildren:() => import('./pages/sales/sales.module').then(m =>m.SalesPageModule),
    canActivate:[AuthGuard]
  },
  {
    path:'edit-stock',
    loadChildren:() => import('./pages/edit-stock/edit-stock.module').then(m=>m.EditStockPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:'sales-report',
    loadChildren:()=> import('./pages/sales-report/sales-report.module').then(m=>m.SalesReportPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:'sales-users',
    loadChildren:()=>import('./pages/sales-users/sales-users.module').then(m =>m.SalesUsersPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:'create-report',
    loadChildren:()=>import('./pages/create-report/create-report.module').then(m=>m.CreateReportPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:"products",
    loadChildren:()=>import('./pages/products/products.module').then(m => m.ProductsPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:"product-item/:categories",
    loadChildren:()=>import('./pages/products-item/products-item.module').then(m => m.ProductsItemPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:"my-user",
    loadChildren:()=>import('./pages/my-user/my-user.module').then(m => m.MyUserPageModule),
    canActivate:[AuthGuard],
  },
  {
    path: 'estadisticas',
    loadChildren: () => import('./pages/estadisticas/estadisticas.module').then( m => m.EstadisticasPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:'support',
    loadChildren: ()=> import('./pages/support/support.module').then(m=>m.SupportPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:'setting-config',
    loadChildren: ()=> import('./pages/setting-config/setting-config.module').then(m=>m.SettingConfigPageModule),
    canActivate:[AuthGuard],
  },
  {
    path:'create-sale-next/:id',
    loadChildren: ()=> import('./pages/create-sale-next/create-sale-next.module').then(m=>m.CamPageNextModule),
    canActivate:[AuthGuard],
  },
  {
    path:'estadisticas-p',
    loadChildren: ()=> import('./pages/estadisticas copy/estadisticas-p.module').then(m=>m.EstadisticasPPageModule),
    canActivate:[AuthGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
